import { gql } from "@apollo/client";

const FOLLOWS = gql`
    query Follows {
        follows {
            _id
            name
            entityType
            created
        }
    }
`

export default FOLLOWS;