import { Button, Spinner } from '@nextui-org/react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { setLocalToken } from '../../../../utils/authUtils';

const CheckoutForm = ({
    subscribingUserToken,
}) => {

    const stripe = useStripe();
    const elements = useElements();
    const location = useLocation();

    const [loadingPaymentElement, setLoadingPaymentElement] = useState(true);
    const [processingPayment, setProcessingPayment] = useState(false);

    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (elements) {
            const element = elements.getElement('payment');
            element.on('loaderstart', () => {
                setLoadingPaymentElement(false);
            })
            element.on('ready', () => {
            })
        }
       
    }, [elements])

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        setProcessingPayment(true);

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setLocalToken(subscribingUserToken);

        const {error} = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: window.location.href,
            },
        });


        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setProcessingPayment(false);
            setErrorMessage(error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            setProcessingPayment(false);
            handleClose();
        }
    };

    return (
        <div className='px-4 mb-2 min-h-36 max-h-[90vh] overflow-scroll'>
            <form onSubmit={handleSubmit}>
                <div className='mb-3 relative'>
                    {loadingPaymentElement && (
                        <div className='w-full'>
                            <div className='flex items-center justify-center h-[280px]'>
                                <Spinner color='default' />
                            </div>
                        </div>
                    )}
                    <PaymentElement />
                </div>
                <Button
                    className='w-full font-semibold mt-2'
                    disableAnimation disableRipple
                    disabled={!stripe || !elements}
                    onClick={handleSubmit}
                    type='submit'
                    isLoading={processingPayment}
                >
                    Pay now
                </Button>
            </form>
        </div>
    )
}

export default CheckoutForm;