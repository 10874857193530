import { Button, Card } from "@nextui-org/react";
import React, { useState, useEffect, useRef } from "react";
import LobbyistSearchResult from "./LobbyistSearchResult/LobbyistSearchResult";
import PublicServantSearchResult from "./PublicServantSearchResult/PublicServantSearchResult";
import InstitutionSearchResult from "./InstitutionSearchResult/InstitutionSearchResult";
import CompanySearchResult from "./CompanySearchResult/CompanySearchResult";
import ConsultingFirmSearchResult from "./ConsultingFirmSearchResult/ConsultingFirmSearchResult";
import SubjectSearchResult from "./SubjectSearchResult/SubjectSearchResult";
import { BG_OFFWHITE } from "../../../../../utils/colours";
import { useNavigate } from "react-router-dom";
import { getEntityPathFromType } from "../../../../../utils/formatters";

const SearchResults = ({
    data,
    handleClose,
    onFetchMore,
    loadingMore,
    showMoreBtn = true,
    maxHeight = `48`,
    condensed = false,
    scroll = false
}) => {
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const resultsRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'ArrowUp') {
                event.preventDefault();
                setSelectedIndex((prevIndex) => 
                    prevIndex > 0 ? prevIndex - 1 : data.search.results.length - 1
                );
            } else if (event.key === 'ArrowDown') {
                event.preventDefault();
                setSelectedIndex((prevIndex) => 
                    prevIndex < data.search.results.length - 1 ? prevIndex + 1 : 0
                );
            } else if (event.key === 'Enter') {
                event.preventDefault();
                if (selectedIndex !== -1) {
                    // Handle selection (e.g., navigate to the selected item's page)
                    let selected = data.search.results[selectedIndex];
                    let entityType = getEntityPathFromType(selected);
                    navigate(`/${entityType}/?name=${encodeURIComponent(selected.name)}`);
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [data, selectedIndex]);

    useEffect(() => {
        if (selectedIndex !== -1 && resultsRef.current) {
            const selectedElement = resultsRef.current.children[selectedIndex];
            if (selectedElement) {
                selectedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [selectedIndex]);

    if (!data || !data.search) {
        return null;
    }

    if (data.search.resultCount === 0) {
        return (
            <div 
                className={`max-h-${maxHeight} overflow-scroll py-16 text-center text-xl text-gray-500`}
            >
                No results
            </div>
        )
    }

    const hasMoreResults = data.search.resultCount > data.search.results.length;

    const results = data.search.results.map((result, i) => {
        let resultBlock;
        const isSelected = i === selectedIndex;
        const commonProps = {
            key: i,
            handleClose: handleClose,
            ...result,
            isSelected,
            condensed: condensed,
            style: isSelected ? { backgroundColor: BG_OFFWHITE } : {}
        };

        switch(result.entityType) {
            case 'LOBBYIST': resultBlock = <LobbyistSearchResult {...commonProps} />; break;
            case 'PUBLIC_SERVANT': resultBlock = <PublicServantSearchResult {...commonProps} />; break;
            case 'INSTITUTION': resultBlock = <InstitutionSearchResult {...commonProps} />; break;
            case 'COMPANY': resultBlock = <CompanySearchResult {...commonProps} />; break;
            case 'CONSULTING_FIRM': resultBlock = <ConsultingFirmSearchResult {...commonProps} />; break;
            case 'SUBJECT': resultBlock = <SubjectSearchResult {...commonProps} />; break;
            default: resultBlock = (
                <Card {...commonProps}
                    className={`mx-4 my-2 px-4 py-2 text-lg rounded-md leading-tight font-medium hover:bg-gray-100 cursor-pointer ${isSelected ? 'bg-blue-100' : ''}`}
                >
                    {result.name}
                </Card>
            )
        }

        return resultBlock;
    });

    return (
        <div 
            className={`${scroll && 'max-h-48'} overflow-scroll ${!condensed ? 'pb-24' : 'pb-1'} hidden-scrollbars`}
            ref={resultsRef}
        >
            {data && !condensed && (
                <div className="mt-4"></div>
            )}
            {data && condensed && (
                <div className="mt-1 mb-1"></div>
            )}
            {results}
            {hasMoreResults && showMoreBtn && (
                <Button
                    size='sm'
                    onClick={onFetchMore}
                    className='mt-4 px-20 font-semibold text-black'
                    style={{ marginLeft: '6.25em' }}
                    isLoading={loadingMore}
                    disableAnimation
                    disableRipple
                >
                    {loadingMore ? 'Loading...' : 'Show more'}
                </Button>
            )}
        </div>
    )
}

export default SearchResults;