import { gql } from "@apollo/client";

const REFRESH_SIGN_IN = gql`
    mutation RefreshSignIn($userID: String) {
        refreshSignIn(userID: $userID) {
            token
            error
            user {
                email
                plan
                paymentStatus
            }
        }
    }
`

export default REFRESH_SIGN_IN;