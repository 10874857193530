import React from 'react';
import { Skeleton } from '@nextui-org/react';
import { NavLink } from 'react-router-dom';
import './LoadingFollowList.css';

const LoadingFollowList = ({

}) => {

    return (
        <div>
            <div 
                className='flex gap-2 items-center h-6 px-2 py-1 mb-2 rounded-lg skeleton'
                style={{ width: '80%' }}
            >
            </div>
            <div 
                className='flex gap-2 items-center h-6 px-2 py-1 mb-2 rounded-lg skeleton'
                style={{ width: '100%' }}
            >
            </div>
            <div 
                className='flex gap-2 items-center h-6 px-2 py-1 mb-2 rounded-lg skeleton'
                style={{ width: '60%' }}
            >
            </div>
        </div>
    )

}

export default LoadingFollowList;