import { Button, Input, Modal, ModalContent } from '@nextui-org/react';
import React, { useState } from 'react';
import validator from 'validator';
import SIGN_IN from '../SignUpModal/mutations/signIn';
import { useMutation } from '@apollo/client';
import { setLocalToken } from '../../../utils/authUtils';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../store/slices/authSlice';
import { SIGN_IN_ERRORS } from './signInErrors';
import { openSignUpModal } from '../../../store/slices/uiSlice';
import { useMediaQuery } from '@uidotdev/usehooks';

const SignInModal = ({
    isOpen,
    onClose
}) => {

    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const onSetEmail = (val) => setEmail(val);

    const [password, setPassword] = useState('');
    const onSetPassword = (val) => setPassword(val);

    const [signInError, setSignInError] = useState(null);

    const [signIn, { loading, error, data }] = useMutation(SIGN_IN);

    const onToggleSignUp = () => {
        dispatch(openSignUpModal());
    }

    const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

    let canSubmit = false;
    if (validator.isEmail(email) && password) {
        canSubmit = true;
    }

    const handleClose = () => {
        setEmail('');
        setPassword('');
        onClose();
    }

    const onSignIn = (e) => {
        
        e.preventDefault();

        if (!canSubmit) {
            return;
        }

        signIn({
            variables: {
                email: email,
                password: password
            },
            onCompleted: (data) => {
                if (data && data.signIn.error) {
                    setSignInError(data.signIn.error);
                }

                if (data && data.signIn.token) {
                    setLocalToken(data.signIn.token);
                    dispatch(setUser(data.signIn.user));
                    setSignInError(null);
                    handleClose();
                }
            }
        })
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={handleClose}
            scrollBehavior={isSmallDevice ? 'outside' : 'inside'}
            classNames={{
                body: 'px-0',
                base: 'px-0 modal',
                closeButton: "bg-white hover:bg-gray-100 active:bg-white/10"
            }}
        >
            <ModalContent className="py-4 max-h-[90vh] overflow-scroll text-black">
                <h2 className="font-bold px-4 tracking-tight mb-3 text-black">
                    Sign in to Lobby Report
                </h2>
                
                <form 
                    onSubmit={onSignIn}
                >
                    <div className='max-h-[80vh] overflow-scroll'>
                        <div className='px-4 mb-4'>
                            <div className="flex flex-col gap-2">
                                {signInError && (
                                    <div className='bg-error text-sm text-center rounded-lg font-semibold py-2'>
                                        {SIGN_IN_ERRORS[signInError]}
                                    </div>
                                )}
                                <Input
                                    aria-label="Email"
                                    placeholder="Email"
                                    className="border-2 rounded-lg border-gray-200"
                                    classNames={{
                                        input: 'text-lg sm:text-sm'
                                    }} 
                                    value={email}
                                    onValueChange={onSetEmail}
                                />
                                <Input 
                                    aria-label="Password"
                                    type='password' 
                                    placeholder="Password"
                                    classNames={{
                                        input: 'text-lg sm:text-sm'
                                    }} 
                                    className="border-2 rounded-lg border-gray-200" 
                                    value={password}
                                    onValueChange={onSetPassword}
                                />
                            </div>
                        </div>
                    </div>
                    
                    <div className='px-4'>
                        <Button
                            type='submit'
                            className={!canSubmit ? "w-full font-semibold bg-gray-200 py-5 pointer-events-none opacity-60" : "w-full py-5 font-semibold"}
                            disableAnimation disableRipple
                            disabled={!canSubmit}
                            // onClick={onSignIn}
                            isLoading={loading}
                        >
                            Sign in
                        </Button>
                    </div>
                </form>

                <div className='text-center text-sm my-3 pt-3 border-top'>
                    <p onClick={onToggleSignUp} className='opacity-60 hover:opacity-90 cursor-pointer'>
                        New to Lobby Report? Sign up
                    </p>
                </div>

            </ModalContent>
        </Modal>
    )

}

export default SignInModal