import { gql } from "@apollo/client";

const SIGN_IN = gql`
    mutation SignIn($email: Email!, $password: String!) {
        signIn(email: $email, password: $password) {
            token
            user {
                _id
                name
                email
                plan
                paymentStatus
            }
            error
        }
    }
`

export default SIGN_IN;