import React, { useEffect, useRef } from 'react';
import CompanyGovContacts from './templates/CompanyGovContacts/CompanyGovContacts';
import { useDispatch, useSelector } from 'react-redux';
import { closeSidePeek } from '../../store/slices/uiSlice';
import { useLocation } from 'react-router-dom';
import PublicServantCompanies from './templates/PublicServantCompanies/PublicServantCompanies';
import InstitutionCompanies from './templates/InstitutionCompanies/InstitutionCompanies';
import InstitutionStaff from './templates/InstitutionStaff/InstitutionStaff';
import ConsultingFirmClients from './templates/ConsultingFirmClients/ConsultingFirmClients';
import LobbyistClients from './templates/LobbyistClients/LobbyistClients';
import LobbyistGovContacts from './templates/LobbyistGovContacts/LobbyistGovContacts';

const SidePeek = ({
    isOpen,
    menuShape
}) => {

    const dispatch = useDispatch();
    const { 
        template, 
        publicServantGivenName
    } = useSelector(state => state.ui.sidePeek);

    const onClose = () => dispatch(closeSidePeek());

    const location = useLocation();

    useEffect(() => {
        onClose();
    }, [location])

    if (!isOpen) {
        return null;
    }

    let content;
    switch (template) {
        case `PUBLIC_SERVANT_COMPANIES`: content = (
            <PublicServantCompanies 
                onClose={onClose} 
                givenName={publicServantGivenName}
            />
        ); break;
        case `INSTITUTION_COMPANIES`: content = (
            <InstitutionCompanies
                onClose={onClose}
            />
        ); break;
        case `INSTITUTION_STAFF`: content = (
            <InstitutionStaff
                onClose={onClose}
            />
        ); break;
        case `CONSULTING_FIRM_CLIENTS`: content = (
            <ConsultingFirmClients
                onClose={onClose}
            />
        ); break;
        case `LOBBYIST_CLIENTS`: content = (
            <LobbyistClients
                onClose={onClose}
            />
        ); break;
        case `LOBBYIST_GOV_CONTACTS`: content = (
            <LobbyistGovContacts
                onClose={onClose}
            />
        ); break;
        case 'COMPANY_GOV_CONTACTS':
        default: content = <CompanyGovContacts onClose={onClose} />;
    }

    return (
        <div className='flex-grow fixed right-0 shadow-lg z-50 bg-white min-w-full md:min-w-96'
            style={{
                width: menuShape === 'EXPANDED' ? `calc((100% - 16rem) / 5 * 2)` : `calc((100% - 2rem) / 5 * 2)`
            }}
        >
            <div className='h-screen'>
                {content}
            </div>
        </div>
    )

}

export default SidePeek;