import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom"

import './index.css'
import { NextUIProvider } from "@nextui-org/react";
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context';
import { Provider } from 'react-redux';
import * as Sentry from "@sentry/react";


import { cacheOptions } from './cache/cacheOptions.js'
import GlobalLayout from './components/GlobalLayout/GlobalLayout.jsx'
import SignInGate from './components/SignInGate/SignInGate.jsx'
import { store } from './store/store.js'
import AuthenticatedSpace from './components/AuthenticatedSpace/AuthenticatedSpace.jsx';
import { getToken } from './utils/authUtils.js';
const FirmIndex = React.lazy(() => import('./components/FirmIndex/FirmIndex.jsx'));
const FirmProfile = React.lazy(() => import('./components/FirmProfile/FirmProfile.jsx'));
const TimelinePage = React.lazy(() => import('./components/TimelinePage/TimelinePage.jsx'));
const ConsultingFirmIndex = React.lazy(() => import('./components/ConsultingFirmIndex/ConsultingFirmIndex.jsx'));
const ConsultingFirmProfile = React.lazy(() => import('./components/ConsultingFirmProfile/ConsultingFirmProfile.jsx'));
const LobbyistIndex = React.lazy(() => import('./components/LobbyistIndex/LobbyistIndex.jsx'));
const LobbyistProfile = React.lazy(() => import('./components/LobbyistProfile/LobbyistProfile.jsx'));
const InstitutionIndex = React.lazy(() => import('./components/InstitutionIndex/InstitutionIndex.jsx'));
const InstitutionProfile = React.lazy(() => import('./components/InstitutionProfile/InstitutionProfile.jsx'));
const PublicServantIndex = React.lazy(() => import('./components/PublicServantIndex/PublicServantIndex.jsx'));
const PublicServantProfile = React.lazy(() => import('./components/PublicServantProfile/PublicServantProfile.jsx'));
const Disclaimer = React.lazy(() => import('./components/StaticPages/Disclaimer/Disclaimer.jsx'));
const PrivacyPolicy = React.lazy(() => import('./components/StaticPages/PrivacyPolicy/PrivacyPolicy.jsx'));
const About = React.lazy(() => import('./components/StaticPages/About/About.jsx'));
import { ErrorBoundary } from 'react-error-boundary';
import PageError from './components/ui/PageError/PageError.jsx';
import ProfileLoading from './components/ui/ProfileLoading/ProfileLoading.jsx';

export const GOOGLE_CLIENT_ID = `553809122591-kprb3jbjfc0umlfpah1057iivdukirm4.apps.googleusercontent.com`

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_API_URL
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
}); 

export const apolloCache = new InMemoryCache(cacheOptions);

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: apolloCache
});

Sentry.init({
  dsn: "https://b6b1c2b449ffcedff0e14b86e3b9e8de@o4508240586932224.ingest.us.sentry.io/4508240588898304",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/lobby-report-api\.onrender\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const router = createBrowserRouter(
  createRoutesFromElements( 
    <Route element={<GlobalLayout />}>
        <Route path='/' element={(
          <div>
            <Suspense fallback={<ProfileLoading />}>
              <SignInGate />
            </Suspense>
          </div>
        )} 
          errorElement={<PageError />}
        />
        <Route path='/about' 
          errorElement={<PageError />}
          element={(
            <div>
                <Suspense fallback={<ProfileLoading />}>
                  <About />
                </Suspense>
            </div>
          )} 
        />
        <Route element={<AuthenticatedSpace />}>
          <Route 
            path='/disclaimer' 
            element={(
              <Suspense fallback={<ProfileLoading />}>
                <Disclaimer />
              </Suspense>
            )} 
            errorElement={<PageError />}
          />
          <Route 
            path='/privacy' 
            element={(
              <Suspense fallback={<ProfileLoading />}>
                <PrivacyPolicy />
              </Suspense>
            )} 
            errorElement={<PageError />}
          />
          <Route 
            path='timeline' 
            element={(
              <Suspense fallback={<ProfileLoading />}>
                <TimelinePage />
              </Suspense>
            )} 
            errorElement={<PageError />}
          />
          <Route 
            path='companies' 
            element={(
              <Suspense fallback={<ProfileLoading />}>
                <FirmIndex />
              </Suspense>
            )} 
            errorElement={<PageError />}
          />
          <Route 
            path='company' 
            element={(
              <Suspense fallback={<ProfileLoading />}>
                <FirmProfile />
              </Suspense>
            )}
            errorElement={<PageError />} 
          />
          <Route 
            path='consulting-firms' 
            element={(
              <Suspense fallback={<ProfileLoading />}>
                <ConsultingFirmIndex />
              </Suspense>
            )} 
            errorElement={<PageError />}
          />
          <Route 
            path='consulting-firm' 
            element={(
              <Suspense fallback={<ProfileLoading />}>
                <ConsultingFirmProfile />
              </Suspense>
            )} 
            errorElement={<PageError />}
          />
          <Route 
            path='lobbyists' 
            element={(
              <Suspense fallback={<ProfileLoading />}>
                <LobbyistIndex />
              </Suspense>
            )} 
            errorElement={<PageError />}
          />
          <Route 
            path='lobbyist' 
            element={(
              <Suspense fallback={<ProfileLoading />}>
                <LobbyistProfile />
              </Suspense>
            )} 
            errorElement={<PageError />}
          />
          <Route 
            path='institutions' 
            element={(
              <Suspense fallback={<ProfileLoading />}>
                <InstitutionIndex  />
              </Suspense>
            )} 
            errorElement={<PageError />}
          />
          <Route 
            path='institution' 
            element={(
              <Suspense fallback={<ProfileLoading />}>
                <InstitutionProfile />
              </Suspense>
            )} 
            errorElement={<PageError />}
          />
          <Route 
            path='public-servants' 
            element={(
              <Suspense fallback={<ProfileLoading />}>
                <PublicServantIndex />
              </Suspense>
            )} 
            errorElement={<PageError />}
          />
          <Route 
            path='public-servant' 
            element={(
              <Suspense fallback={<ProfileLoading />}>
                <PublicServantProfile />
              </Suspense>
            )} 
            errorElement={<PageError />}
          />
        </Route>
    </Route>
  )
);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <NextUIProvider>
      <main className="light text-foreground bg-background">
        <Provider store={store}>
          <ErrorBoundary fallback={<PageError />}>
            <ApolloProvider client={apolloClient}>
              <RouterProvider router={router} />
            </ApolloProvider>
          </ErrorBoundary>
        </Provider>
      </main>
    </NextUIProvider>
  </React.StrictMode>,
)
