import { faCanadianMapleLeaf } from "@fortawesome/free-brands-svg-icons";
import { faBriefcase, faCalendar, faIndustry, faInstitution, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSearchParams } from "react-router-dom";
import InstitutionName from "../../ui/InstitutionName/InstitutionName";
import { DARK_GREY, MID_GREY } from "../../../utils/colours";

const Breadcrumb = ({
    location
}) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const name = searchParams.get(`name`);

    let breadcrumb, icon;
    if (location.pathname === '/timeline') { 
        breadcrumb = 'Recent Lobbying';
        icon = faCalendar;
    }

    if (location.pathname === '/companies') { 
        breadcrumb = 'Companies';
        icon = faIndustry;
    }

    if (location.pathname === '/consulting-firms') { 
        breadcrumb = 'Consulting Firms';
        icon = faBriefcase;
    }

    if (location.pathname === '/institutions') { 
        breadcrumb = 'Parts of Government';
        icon = faInstitution;
    }

    if (location.pathname === '/public-servants') { 
        breadcrumb = 'Government Officials';
        icon = faCanadianMapleLeaf;
    }

    if (location.pathname === '/lobbyists') { 
        breadcrumb = 'Lobbyists';
        icon = faUser;
    }

    if (location.pathname.includes('/company/')) { 
        breadcrumb = name;
        icon = faIndustry;
    }

    if (location.pathname.includes('/consulting-firm/')) { 
        breadcrumb = name;
        icon = faBriefcase;
    }

    if (location.pathname.includes('/lobbyist/')) { 
        breadcrumb = name;
        icon = faUser;
    }

    if (location.pathname.includes('/institution/')) { 
        breadcrumb = <InstitutionName fullName={name} />;
        icon = faInstitution;
    }

    if (location.pathname.includes('/public-servant/')) { 
        breadcrumb = name;
        icon = faCanadianMapleLeaf;
    }

    return (
        <div className="flex gap-2 items-center mt-2.5">
            <div className="relative -t-px">
                <FontAwesomeIcon 
                    icon={icon} 
                    className='fas' 
                    size="xs"
                    color={MID_GREY}
                />
            </div> 
            <p className='tracking-tight font-bold text-lg text-mid-grey'>
                {breadcrumb}
            </p>
        </div>
      
    )
}

export default Breadcrumb;