import { gql } from "@apollo/client";

const CONSIDER_UPGRADE = gql`
    mutation ConsiderUpgrade($cycle: String) {
        considerUpgrade(cycle: $cycle) {
            token
            user {
                _id
                name
                email
                token
                customerID
                subscriptionID
            }
            paymentClientSecret
            error
        }
    }
`

export default CONSIDER_UPGRADE;