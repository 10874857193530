import { gql } from "@apollo/client";

const COMPANY_GOV_CONTACTS = gql`
    query CompanyGovContacts($name: String, $offset: Int) {
        totalRelationships(firm: $name)
        relationships(firm: $name, offset: $offset, limit: 20) {
            _id
            officialName
            officialTitles
            officialInstitutions
            meetings
        }
    }
`

export default COMPANY_GOV_CONTACTS;