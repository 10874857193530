import { useMutation } from '@apollo/client';
import { Button, Modal, ModalContent, Tabs, Tab, Input } from '@nextui-org/react';
import React, { useState } from 'react';
import validator from 'validator';
// import SIGN_UP from './mutations/signUp';
import { useDispatch } from 'react-redux';
import { setLocalToken } from '../../../utils/authUtils';
import { setUser } from '../../../store/slices/authSlice';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// import SignUpForm from './SignUpForm/SignUpForm';
import { openSignInModal } from '../../../store/slices/uiSlice';
import CheckoutForm from '../SignUpModal/CheckoutForm/CheckoutForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import CONSIDER_UPGRADE from './mutations/considerUpgrade';
import SIGN_UP from '../SignUpModal/mutations/signUp';
import { SIGN_UP_ERRORS } from '../SignUpModal/SignUpForm/signUpErrors';
import { useMediaQuery } from '@uidotdev/usehooks';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const UpgradeModal = ({
    isOpen,
    onClose,
    isNewUser = false,
    canClose,
    blurBG
}) => {

    const dispatch = useDispatch();

    const [stripeIntentSecret, setStripeIntentSecret] = useState(null);
    
    const [subscribingUser, setSubscribingUser] = useState(null);
    const [subscribingUserToken, setSubscribingUserToken] = useState(null);

    const [proCycle, setProCycle] = useState('ANNUAL');
    const onSetProCycle = (key) => setProCycle(key);

    const [screen, setScreen] = useState('SUBSCRIBE');

    const [email, setEmail] = useState('');
    const onSetEmail = (val) => setEmail(val);

    const [password, setPassword] = useState('');
    const onSetPassword = (val) => setPassword(val);

    const [signUpError, setSignUpError] = useState(null);

    const onNavToForm = () => setScreen('SIGNUP_FORM');

    const [signUp, { 
        loading: signUpLoading, 
        error: signUpReqError,
        data: signUpData
    }] = useMutation(SIGN_UP);

    let canSubmit = false;
    if (!isNewUser || (validator.isEmail(email) && password)) {
        canSubmit = true;
    }

    const onSignUpPro = (e) => {

        e.preventDefault();

        if (!canSubmit) {
            return;
        }

        signUp({
            variables: {
                email: email,
                password: password,
                plan: 'PRO',
                cycle: proCycle
            },
            onCompleted: (data) => {

                if (data && data.signUp.error) {
                    setSignUpError(data.signUp.error);
                }

                if (data && data.signUp.token) {
                    setLocalToken(data.signUp.token);
                    dispatch(setUser(data.signUp.user));
                    setSubscribingUser(data.signUp.user);
                    setSubscribingUserToken(data.signUp.token);
                    setStripeIntentSecret(data.signUp.paymentClientSecret)
                    setSignUpError(null);
                    setScreen('CHECKOUT')
                }
            }
        })
    }

    const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

    const [considerUpgrade, { 
        loading: considerUpgradeLoading, 
        error: considerUpgradeError, 
        data: considerUpgradeData 
    }] = useMutation(CONSIDER_UPGRADE);

    const onCheckout = (e) => {
        
        e.preventDefault();

        considerUpgrade({
            variables: {
                cycle: proCycle
            },
            onCompleted: (data) => {
                setStripeIntentSecret(data.considerUpgrade.paymentClientSecret);
                if (data && data.considerUpgrade.token) {
                    setLocalToken(data.considerUpgrade.token);
                    dispatch(setUser(data.considerUpgrade.user));
                    setSubscribingUser(data.considerUpgrade.user);
                    setSubscribingUserToken(data.considerUpgrade.token);
                    setStripeIntentSecret(data.considerUpgrade.paymentClientSecret)
                    setScreen('CHECKOUT');
                }
            }

           
        })
    }

    const stripeOptions = {
        // passing the client secret obtained from the server
        clientSecret: stripeIntentSecret,
        appearance: {
            variables: {
                fontSizeBase: '15px',
                fontWeightNormal: 500
            }
        }
    };

    const handleClose = () => {
        setScreen('SUBSCRIBE')
        setSignUpError(null)
        onClose();
    }

    const onEmailBlur = () => {
        if (!validator.isEmail(email) && email != '') {
            setSignUpError('INVALID_EMAIL');
        }
        if (signUpError && validator.isEmail(email)) {
            setSignUpError(null);
        }
    }

    const onToggleSignIn = () => {
        console.log('open sign in')
        dispatch(openSignInModal());
        // handleClose();
    }

    return (
        <Modal 
            isOpen={isOpen}
            onClose={handleClose}
            scrollBehavior={isSmallDevice ? 'outside' : 'inside'}
            classNames={{
                body: 'px-0',
                base: 'px-0 modal',
                closeButton: canClose ? "bg-white hover:bg-gray-100 active:bg-white/10" : "hidden",
            }}
            style={{
                backdropFilter: "blur(4px)"
            }}
        >
            <ModalContent className="py-4 max-h-[90vh] overflow-scroll text-black hidden-scrollbars">
                <h2 className="font-medium px-4 tracking-tight mb-2 text-black text-center text-4xl pt-4 pb-3">
                    Get full access
                </h2>
                {screen === 'SUBSCRIBE' && (
                    <div>
                        <div className='border-2 border-gray-200 rounded-lg mx-4 px-7 py-6'>
                            <p className='mb-2'>
                                <span className='font-bold tracking-tight bg-mid-grey rounded-3xl text-white px-4 py-1'>
                                    Lobby Report Pro
                                </span>
                            </p>
                            <div className='flex justify-between items-start'>
                                {proCycle === 'MONTHLY' && (
                                    <p>
                                        <span className='text-2xl font-bold'>
                                            $7 
                                        </span>
                                        <span className='opacity-70 ml-0.5 font-medium'>
                                            /
                                        </span>
                                        <span className='opacity-70 ml-0.5 font-medium'>
                                            month
                                        </span>
                                    </p>
                                )}
                                {proCycle === 'ANNUAL' && (
                                    <p>
                                        <span className='text-2xl font-bold'>
                                            $70
                                        </span>
                                        <span className='opacity-70 ml-0.5 font-medium'>
                                            /
                                        </span>
                                        <span className='opacity-70 ml-0.5 font-medium'>
                                            year
                                        </span>
                                    </p>
                                )}
                                <div className="flex flex-wrap">
                                    <Tabs 
                                        aria-label="Tabs" 
                                        color='default' 
                                        radius="full" 
                                        size='sm'
                                        onSelectionChange={onSetProCycle}
                                        selectedKey={proCycle}
                                        classNames={{
                                            base: 'bg-gray-100 rounded-3xl gap-0.5',
                                            tabList: 'gap-0.5'
                                        }}
                                    >
                                        <Tab key="MONTHLY" title="Monthly" className='px-3'/>
                                        <Tab key="ANNUAL" title={
                                            <span>Annual <span className='opacity-50'>(17% off)</span></span>
                                        } className='px-3'/>
                                    </Tabs>
                                </div>
                            </div>
                            
                            <ul className='mt-2 mb-4 flex flex-col gap-0.5 font-medium'>
                                <li>
                                    <FontAwesomeIcon 
                                        icon={faCheck} 
                                        className='mr-3'
                                    />
                                    Unlimited profiles
                                </li>
                                <li>
                                    <FontAwesomeIcon 
                                        icon={faCheck} 
                                        className='mr-3'
                                    />
                                    Full client lists for lobbyists
                                </li>
                            </ul>
                            <form onSubmit={isNewUser ? onSignUpPro : onCheckout}>
                                {isNewUser && (
                                    <div className="flex flex-col gap-2 mb-2">
                                        {signUpError && (
                                            <div className='bg-error text-sm text-center rounded-lg font-semibold py-2'>
                                                {SIGN_UP_ERRORS[signUpError]}
                                            </div>
                                        )}
                                        <Input 
                                            aria-label="Email"
                                            placeholder="Email"
                                            onBlur={onEmailBlur}
                                            className="border-2 rounded-lg border-gray-200" 
                                            classNames={{
                                                inputWrapper: 'shadow-none outline-none'
                                            }}    
                                            value={email}
                                            onValueChange={onSetEmail}
                                        />
                                        <Input 
                                            aria-label="Password"
                                            type='password' 
                                            placeholder="Password"
                                            className="border-2 rounded-lg border-gray-200"
                                            classNames={{
                                                inputWrapper: 'shadow-none'
                                            }}       
                                            value={password}
                                            onValueChange={onSetPassword}
                                        />
                                    </div>
                                )}
                                <div className='mt-4'>
                                    <Button 
                                        type='submit'
                                        className={!canSubmit ? "w-full font-semibold bg-gray-200 py-5 pointer-events-none opacity-60" : "w-full py-5 font-semibold"}
                                        disableAnimation disableRipple
                                        disabled={!canSubmit}
                                        onClick={isNewUser ? onSignUpPro : onCheckout}
                                        isLoading={considerUpgradeLoading || signUpLoading}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            </form>
                        </div>
                        <div className='text-center text-sm my-3 pt-3 border-top'>
                            <p 
                                onClick={onToggleSignIn} 
                                className='opacity-60 hover:opacity-90 cursor-pointer'
                            >
                                Already have an account? Sign in
                            </p>
                        </div>
                    </div>
                )}
            
                {screen === 'CHECKOUT' && stripeIntentSecret && (
                    <Elements stripe={stripePromise} options={stripeOptions}
                    >
                        <CheckoutForm
                            stripeOptions={stripeOptions}
                            stripePromise={stripePromise}
                            subscribingUser={subscribingUser}
                            subscribingUserToken={subscribingUserToken}
                            onBack={onNavToForm}
                        />
                    </Elements>
                )}

            </ModalContent>
        </Modal>
    )
}

export default UpgradeModal;