import { Elements } from "@stripe/react-stripe-js";
import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import PaymentStatus from "../PaymentStatus/PaymentStatus";
import { loadStripe } from "@stripe/stripe-js";

console.log('publishable key', import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY)
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const PaymentStatusContainer = ({

}) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const payment_intent_client_secret = searchParams.get('payment_intent_client_secret');
    if (!payment_intent_client_secret) {
        return null;
    }

    return (
        <Elements stripe={stripePromise} options={{
            clientSecret: payment_intent_client_secret
        }}>
            <PaymentStatus />
        </Elements>
    )
}

export default PaymentStatusContainer;