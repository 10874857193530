import { gql } from "@apollo/client";

const SEARCH = gql`
    query Search($query: String, $offset: Int, $limit: Int) {
        search(query: $query, offset: $offset, limit: $limit) {
            resultCount
            results {
                name
                entityType
                meetingCount
                title
                branch
                institution
                officialCount
                consultingFirm
                highlight {
                    value
                    type
                }
            }
        }
    }
`

export default SEARCH;