import { gql } from "@apollo/client";

const INSTITUTION_STAFF = gql`
    query InstitutionStaff($institution: String, $offset: Int) {
        totalOfficials(institution: $institution)
        officials(institution: $institution, offset: $offset, limit: 50) {
            _id
            name
            title
            branch
            institution
            meetingCount
        }
    }
`

export default INSTITUTION_STAFF;