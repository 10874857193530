import React from "react";
import { Helmet } from "react-helmet";
import { useLocation, useSearchParams } from "react-router-dom";

const getTitleAndDescription = (location, searchParams) => {
  let name = searchParams ? searchParams.get('name') : null;
  let title = 'Lobby Report'
  let description;

  if (location.pathname === '/public-servant/' && name) {
    title = `${name} on Lobby Report`;
    description = 'A government official in Canada'
  }

  if (location.pathname === '/company/' && name) {
    title = `${name} on Lobby Report`;
    description = 'A lobby group in Canada'
  }

  if (location.pathname === '/consulting-firm/' && name) {
    title = `${name} on Lobby Report`;
    description = 'A government relations firm in Canada'
  }

  if (location.pathname === '/lobbyist/' && name) {
    title = `${name} on Lobby Report`;
    description = 'A lobbyist in Canada'
  }

  if (location.pathname === '/institution/' && name) {
    title = `${name} on Lobby Report`;
    description = 'A part of government in Canada'
  }

  if (location.pathname === '/timeline') {
    title = `Recent lobbying on Lobby Report`;
    description = 'Timeline'
  }

  if (location.pathname === '/companies') {
    title = `Companies on Lobby Report`;
    description = 'Companies that lobby the Government of Canada'
  }

  if (location.pathname === '/consulting-firms') {
    title = `Consulting Firms on Lobby Report`;
    description = 'Government relations and public affairs firms'
  }

  if (location.pathname === '/lobbyists') {
    title = `Lobbyists on Lobby Report`;
    description = 'A list of active government relations professionals'
  }

  if (location.pathname === '/public-servants') {
    title = `Government Officials on Lobby Report`;
    description = 'A list of frequent government contacts for lobbyists'
  }

  if (location.pathname === '/institutions') {
    title = `Parts of Government on Lobby Report`;
    description = 'Government institutions in Canada'
  }

  return [title, description]

}

const SEO = ({ image, location, searchParams }) => {
    // Get the site's base URL dynamically
    const siteUrl = window.location.origin;
    
    // If no image is passed, use the default one
    const socialImage = image || '/images/social-share.png';
    
    // Ensure image URL is absolute
    const fullImageUrl = socialImage.startsWith('http') 
      ? socialImage 
      : `${siteUrl}${socialImage}`;

    const [title, description] = getTitleAndDescription(location, searchParams)
  
    return (
      <Helmet>
        {/* Basic meta tags */}
        <title>{title}</title>
        <meta name="description" content={description} />
  
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content={'Lobby Report'} />
        <meta property="og:image" content={fullImageUrl} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
  
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={window.location.href} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={fullImageUrl} />
      </Helmet>
    );
  };
  
  export default SEO;