import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  sidePeek: {
    open: false
  },
  authModal: null // SIGN_UP | SIGN_IN
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
        setSidePeek: (state, action) => {
            state.sidePeek = action.payload;
        },
        closeSidePeek: (state) => {
            state.sidePeek.open = false;
            state.sidePeek.template = null;
        },
        openSignUpModal: (state) => {
          state.authModal = 'SIGN_UP';
        },
        openSignInModal: (state) => {
          state.authModal = 'SIGN_IN';
        },
        openUpgradeModal: (state) => {
          state.authModal = 'UPGRADE'
        },
        closeAuthModal: (state) => {
          state.authModal = null;
        }
    },
})

// Action creators are generated for each case reducer function
export const { 
  setSidePeek, 
  closeSidePeek,
  openSignInModal,
  openSignUpModal,
  openUpgradeModal,
  closeAuthModal
} = uiSlice.actions

export default uiSlice.reducer