import { gql } from "@apollo/client";

const LOBBYIST_GOV_CONTACTS = gql`
    query LobbyistGovContacts($lobbyist: String, $offset: Int) {
        totalRelationships(lobbyist: $lobbyist)
        relationships(lobbyist: $lobbyist, offset: $offset, limit: 20) {
            _id
            clients
            officialName
            officialTitles
            officialBranches
            officialInstitutions
            meetings
        }
    }
`

export default LOBBYIST_GOV_CONTACTS;