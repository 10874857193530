import React from 'react';

const SearchResultCard = ({
    children,
    style,
    condensed
}) => {

    let classes = [
        'group', 
        'my-px', 
        'px-5', 
        '-my-px', 
        'text-lg', 
        'leading-tight', 
        'font-medium', 
        'hover:bg-offwhite', 
        'cursor-pointer',
        'transition-none',
        'duration-75',
        'rounded-lg',
        
    ]

    if (!condensed) {
        classes.push('mx-4', 'py-4', 'rounded-xl')
    }

    if (condensed) {
        classes.push('mx-1', 'my-1', 'py-1.5')
    }

    return (
        <div
            className={classes.join(' ')}
            style={style}
        >
            {children}
        </div>
    )
}

export default SearchResultCard;