import { faBars, faSearch, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDisclosure } from '@nextui-org/react';
import React from 'react';
import { Link } from 'react-router-dom';
import SearchModal from '../Search/SearchModal/SearchModal';

const MobileTopMenu = ({
    openMobileMenu
}) => {

    const {
        isOpen: searchIsOpen, 
        onOpen: onOpenSearch, 
        onOpenChange, 
        onClose: onCloseSearch
    } = useDisclosure();
    
    return (
        <div 
            className='text-white py-0.5 pl-1.5 pr-1.5 flex mx-2 mt-1.5 justify-between items-center'
            style={{
                borderRadius: 200,
                background: `rgba(111, 111, 82, 0.8)`,
                backdropFilter: `blur(8px)`,
                WebkitBackdropFilter: `blur(8px)`
            }}
        >
            <button
                onClick={openMobileMenu}
            >
                <FontAwesomeIcon
                    icon={faBars}
                />
            </button>
            
            <Link 
                to={`/`} 
                className='text-white text-xl tracking-tight font-bold'
            >
                Lobby Report
            </Link>

            <span className='flex justify-between items-center gap-0.5'>
                <button
                    onClick={onOpenSearch}
                >
                    <FontAwesomeIcon
                        icon={faSearch}
                    />
                </button>
            </span>

            <SearchModal
                isOpen={searchIsOpen}
                onClose={onCloseSearch}
                menuShape={"MOBILE"}
            />
            
        </div>
    )
}

export default MobileTopMenu;