import { gql } from "@apollo/client";

const SIGN_UP = gql`
    mutation SignUp($email: Email!, $password: String!, $plan: String, $cycle: String) {
        signUp(email: $email, password: $password, plan: $plan, cycle: $cycle) {
            token
            user {
                _id
                name
                email
                token
                customerID
                subscriptionID
            }
            paymentClientSecret
            error
        }
    }
`

export default SIGN_UP;