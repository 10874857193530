import { gql } from "@apollo/client";

const PUBLIC_SERVANT_COMPANIES = gql`
    query PublicServantCompanies($name: String, $offset: Int) {
        totalRelationships(official: $name)
        relationships(official: $name, offset: $offset, limit: 20) {
            _id
            clientName
            meetings
        }
    }
`

export default PUBLIC_SERVANT_COMPANIES;