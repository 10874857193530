import React from "react";

export const getInstitutionName = (fullName) => {

    let name;
    switch (fullName) {
        case 'Infrastructure Canada (INFC)': name = 'Infrastructure Canada'; break;
        case 'Innovation, Science and Economic Development Canada (ISED)': name = 'Innovation, Science and Economic Development Canada'; break;
        case 'Canada Revenue Agency (CRA)': name = 'Canada Revenue Agency'; break;
        case `Prime Minister's Office (PMO)`: name = `Prime Minister's Office`; break;
        case `Transport Canada (TC)`: name = `Transport Canada`; break;
        case `Intergovernmental Affairs Secretariat (IGA)`: name = `Intergovernmental Affairs Secretariat`; break;
        case `Health Canada (HC)`: name = 'Health Canada'; break;
        case `Finance Canada (FIN)`: name = 'Finance Canada'; break;
        case 'Indigenous Services Canada (ISC)': name = 'Indigenous Services Canada'; break;
        case `Accessibility Standards Canada`: name = 'Accessibility Standards Canada'; break;
        case `Fisheries and Oceans Canada (DFO)`: name = 'Fisheries and Oceans Canada'; break;
        case `Agriculture and Agri-Food Canada (AAFC)`: name = 'Agriculture and Agri-Food Canada'; break;
        case 'Natural Resources Canada (NRCan)': name = 'Natural Resources Canada'; break;
        case `House of Commons`: name = 'House of Commons'; break;
        case `Senate of Canada`: name = `Senate of Canada`; break;
        case `Employment and Social Development Canada (ESDC)`: name = 'Employment and Social Development Canada'; break;
        case `Global Affairs Canada (GAC)`: name = 'Global Affairs Canada'; break;
        case `Women and Gender Equality (WAGE)`: name = 'Women and Gender Equality'; break;
        case `Canadian Heritage (PCH)`: name = 'Canadian Heritage'; break;
        case `National Defence (DND)`: name = `National Defence`; break;
        case `Environment and Climate Change Canada (ECCC)`: name = `Environment and Climate Change Canada`; break;
        case `Export Development Canada (EDC)`: name = `Export Development Canada`; break;
        case `Canada Economic Development for Quebec Regions`: name = 'Canada Economic Development for Quebec Regions'; break;
        case `Canadian Radio-television and Telecommunications Commission (CRTC)`: name = 'Canadian Radio-television and Telecommunications Commission'; break;
        case 'Treasury Board Of Canada Secretariat (TBS)': name = 'Treasury Board Of Canada Secretariat'; break;
        case 'Public Safety Canada (PS)': name = 'Public Safety Canada'; break;
        case 'Justice Canada (JC)': name = 'Justice Canada'; break;
        case 'Impact Assessment Agency of Canada (IAAC)': name = 'Impact Assessment Agency of Canada'; break;
        case 'National Research Council (NRC)': name = 'National Research Council'; break;
        case 'Immigration, Refugees and Citizenship Canada (IRCC)': name = `Immigration, Refugees and Citizenship Canada`; break;
        case 'Crown-Indigenous Relations and Northern Affairs Canada (CIRNAC)': name = 'Crown-Indigenous Relations and Northern Affairs Canada'; break;
        case `Canada Border Services Agency (CBSA)`: name = 'Canada Border Services Agency '; break;
        case `Public Services and Procurement Canada (PSPC)`: name = 'Public Services and Procurement Canada'; break;
        case `Canadian Northern Economic Development Agency (CanNor)`: name = `Canadian Northern Economic Development Agency`; break;
        case `Federal Economic Development Agency for Southern Ontario (FedDev)`: name = `Federal Economic Development Agency for Southern Ontario`; break;
        case `Privy Council Office (PCO)`: name = `Privy Council Office`; break;
        case `Canada School of Public Service (CSPS)`: name = `Canada School of Public Service`; break;
        case `Royal Canadian Mounted Police (RCMP)`: name = `Royal Canadian Mounted Police`; break;
        case `Atlantic Canada Opportunities Agency (ACOA)`: name = `Atlantic Canada Opportunities Agency`; break;
        case `Prairies Economic Development Canada (PrairiesCan)`: name = `Prairies Economic Development Canada`; break;
        case `Veterans Affairs Canada (VAC)`: name = `Veterans Affairs Canada`; break;
        case `Pacific Economic Development Canada (PacifiCan)`: name = `Pacific Economic Development Canada`; break;
        case `Canadian Security Intelligence Service (CSIS)`: name = `Canadian Security Intelligence Service`; break;
        case `Canadian Space Agency (CSA)`: name = `Canadian Space Agency`; break;
        case `Financial Consumer Agency of Canada (FCAC)`: name = `Financial Consumer Agency of Canada`; break;
        case `Shared Services Canada (SSC)`: name = `Shared Services Canada`; break;
        case `Communications Security Establishment Canada (CSEC)`: name = `Communications Security Establishment Canada`; break;
        case `Service Canada (ServCan)`: name = `Service Canada`; break;
        case `Transportation Safety Board of Canada (TSB)`: name = `Transportation Safety Board of Canada`; break;
        case `Canada Mortgage and Housing Corporation (CMHC)`: name = `Canada Mortgage and Housing Corporation`; break;
        case `Rural Economic Development (Minister’s Office)`: name = `Rural Economic Development (Minister’s Office)`; break;
        default: name = fullName;
    }

    return name;

}

const InstitutionName = ({
    fullName
}) => {

    let name;
    switch (fullName) {
        case 'Infrastructure Canada (INFC)': name = 'Infrastructure Canada'; break;
        case 'Innovation, Science and Economic Development Canada (ISED)': name = 'Innovation, Science and Economic Development Canada'; break;
        case 'Canada Revenue Agency (CRA)': name = 'Canada Revenue Agency'; break;
        case `Prime Minister's Office (PMO)`: name = `Prime Minister's Office`; break;
        case `Transport Canada (TC)`: name = `Transport Canada`; break;
        case `Intergovernmental Affairs Secretariat (IGA)`: name = `Intergovernmental Affairs Secretariat`; break;
        case `Health Canada (HC)`: name = 'Health Canada'; break;
        case `Finance Canada (FIN)`: name = 'Finance Canada'; break;
        case 'Indigenous Services Canada (ISC)': name = 'Indigenous Services Canada'; break;
        case `Accessibility Standards Canada`: name = 'Accessibility Standards Canada'; break;
        case `Fisheries and Oceans Canada (DFO)`: name = 'Fisheries and Oceans Canada'; break;
        case `Agriculture and Agri-Food Canada (AAFC)`: name = 'Agriculture and Agri-Food Canada'; break;
        case 'Natural Resources Canada (NRCan)': name = 'Natural Resources Canada'; break;
        case `House of Commons`: name = 'House of Commons'; break;
        case `Senate of Canada`: name = `Senate of Canada`; break;
        case `Employment and Social Development Canada (ESDC)`: name = 'Employment and Social Development Canada'; break;
        case `Global Affairs Canada (GAC)`: name = 'Global Affairs Canada'; break;
        case `Women and Gender Equality (WAGE)`: name = 'Women and Gender Equality'; break;
        case `Canadian Heritage (PCH)`: name = 'Canadian Heritage'; break;
        case `National Defence (DND)`: name = `National Defence`; break;
        case `Environment and Climate Change Canada (ECCC)`: name = `Environment and Climate Change Canada`; break;
        case `Export Development Canada (EDC)`: name = `Export Development Canada`; break;
        case `Canada Economic Development for Quebec Regions`: name = 'Canada Economic Development for Quebec Regions'; break;
        case `Canadian Radio-television and Telecommunications Commission (CRTC)`: name = 'Canadian Radio-television and Telecommunications Commission'; break;
        case 'Treasury Board Of Canada Secretariat (TBS)': name = 'Treasury Board Of Canada Secretariat'; break;
        case 'Public Safety Canada (PS)': name = 'Public Safety Canada'; break;
        case 'Justice Canada (JC)': name = 'Justice Canada'; break;
        case 'Impact Assessment Agency of Canada (IAAC)': name = 'Impact Assessment Agency of Canada'; break;
        case 'National Research Council (NRC)': name = 'National Research Council'; break;
        case 'Immigration, Refugees and Citizenship Canada (IRCC)': name = `Immigration, Refugees and Citizenship Canada`; break;
        case 'Crown-Indigenous Relations and Northern Affairs Canada (CIRNAC)': name = 'Crown-Indigenous Relations and Northern Affairs Canada'; break;
        case `Canada Border Services Agency (CBSA)`: name = 'Canada Border Services Agency '; break;
        case `Public Services and Procurement Canada (PSPC)`: name = 'Public Services and Procurement Canada'; break;
        case `Canadian Northern Economic Development Agency (CanNor)`: name = `Canadian Northern Economic Development Agency`; break;
        case `Federal Economic Development Agency for Southern Ontario (FedDev)`: name = `Federal Economic Development Agency for Southern Ontario`; break;
        case `Privy Council Office (PCO)`: name = `Privy Council Office`; break;
        case `Canada School of Public Service (CSPS)`: name = `Canada School of Public Service`; break;
        case `Royal Canadian Mounted Police (RCMP)`: name = `Royal Canadian Mounted Police`; break;
        case `Atlantic Canada Opportunities Agency (ACOA)`: name = `Atlantic Canada Opportunities Agency`; break;
        case `Prairies Economic Development Canada (PrairiesCan)`: name = `Prairies Economic Development Canada`; break;
        case `Veterans Affairs Canada (VAC)`: name = `Veterans Affairs Canada`; break;
        case `Pacific Economic Development Canada (PacifiCan)`: name = `Pacific Economic Development Canada`; break;
        case `Canadian Security Intelligence Service (CSIS)`: name = `Canadian Security Intelligence Service`; break;
        case `Canadian Space Agency (CSA)`: name = `Canadian Space Agency`; break;
        case `Financial Consumer Agency of Canada (FCAC)`: name = `Financial Consumer Agency of Canada`; break;
        case `Shared Services Canada (SSC)`: name = `Shared Services Canada`; break;
        case `Communications Security Establishment Canada (CSEC)`: name = `Communications Security Establishment Canada`; break;
        case `Service Canada (ServCan)`: name = `Service Canada`; break;
        case `Transportation Safety Board of Canada (TSB)`: name = `Transportation Safety Board of Canada`; break;
        case `Canada Mortgage and Housing Corporation (CMHC)`: name = `Canada Mortgage and Housing Corporation`; break;
        case `Rural Economic Development (Minister’s Office)`: name = `Rural Economic Development (Minister’s Office)`; break;
        default: name = fullName;
    }

    return name;

}

export default InstitutionName;