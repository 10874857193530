import { gql } from "@apollo/client";

const INSTITUTION_COMPANIES = gql`
    query InstitutionCompanies($institution: String, $offset: Int) {
        totalRelationships(institution: $institution)
        relationships(institution: $institution, offset: $offset, limit: 20) {
            _id
            clientName
            meetings
        }
    }
`

export default INSTITUTION_COMPANIES;