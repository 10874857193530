import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, CircularProgress } from '@nextui-org/react';
import InfiniteScroll from 'react-infinite-scroller';
import INSTITUTION_STAFF from './queries/institutionStaff';
import PublicServantBlock from '../../../FirmProfile/FirmConnections/PublicServantBlock/PublicServantBlock';
import LoadingSidePeek from '../../../ui/LoadingSidePeek/LoadingSidePeek';
import LoadingSpinner from '../../../ui/LoadingSpinner/LoadingSpinner';

const InstitutionStaff = ({
    onClose
}) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const institution = searchParams.get('name');
    const [loadingMore, setLoadingMore] = useState(false);

    const { loading, error, data, fetchMore } = useQuery(INSTITUTION_STAFF, {
        variables: {
            institution: institution,
            offset: 0
        },
        fetchPolicy: 'cache-and-network'
    });

    const onFetchMore = () => {
        setLoadingMore(true);
        fetchMore({
            variables: {
                offset: data.officials.length
            }
        })
    }

    useEffect(() => {
        if (loadingMore) {
            setLoadingMore(false);
        }
    }, [data])

    let body;

    if (loading) {
        return (
            <div>
                <div className='pl-8 pr-1.5 pt-1 pb-4 sticky top-0 z-10 bg-white h-20 flex border-b border-gray-200'>
                    <div className='flex-grow pt-2.5'>
                        <h3 className='text-xl font-bold mb-0 leading-tight truncate w-4/5'>{institution}</h3>
                        <h3 className='text-xl font-medium mt-0 leading-tight text-gray-500 truncate w-4/5'></h3>
                    </div>
                    <div>
                        <button 
                            className='px-2 py-0 bg-white hover:bg-gray-100' 
                            onClick={onClose}
                        >
                            <span className='text-2xl font-normal text-gray-500'>×</span>
                        </button>
                    </div>
                </div>
                <LoadingSidePeek />
            </div>
        )
    }

    const staffList = data.officials.map((rel, i) => {
        return (
            <PublicServantBlock
                key={i}
                officialName={rel.name}
                meetings={rel.meetingCount}
                {...rel}
            />
        )
    });

    body = (
        <div 
            className='overflow-scroll hidden-scrollbars'
            style={{
                height: `calc(100vh - 80px)`
            }}
        >
            <div className='px-8 pt-6 pb-16 flex flex-col gap-3'>
                {staffList}
                {data.totalOfficials > data.officials.length && (
                    <Button 
                        size='sm'
                        onClick={onFetchMore}
                        className='mt-4 mx-8 font-semibold'
                        isLoading={loadingMore}
                        disableAnimation
                        disableRipple
                    >
                        {loadingMore ? 'Loading...' : 'Show more'}
                    </Button>
                )}
            </div>
        </div>
    )

    return (
        <div>
            <div className='pl-8 pr-1.5 pt-1 pb-4 sticky top-0 z-10 bg-white h-20 flex border-b border-gray-200'>
                <div className='flex-grow pt-2.5'>
                    <h3 className='text-xl font-bold mb-0 leading-tight truncate w-80'>{institution}</h3>
                    <h3 className='text-xl font-medium mt-0 leading-tight text-gray-500 truncate w-80'>Public office holders</h3>
                </div>
                <div>
                    <button 
                        className='px-2 py-0 bg-white hover:bg-gray-100 absolute top-1 right-1' 
                        onClick={onClose}
                    >
                        <span className='text-2xl font-normal text-gray-500'>×</span>
                    </button>
                </div>
            </div>
            {body}
        </div>
    )
}

export default InstitutionStaff;