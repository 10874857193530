import { faCanadianMapleLeaf } from '@fortawesome/free-brands-svg-icons';
import { faBriefcase, faIndustry, faInstitution, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { NavLink } from 'react-router-dom';

const FollowListItem = ({
    name,
    searchName,
    entityType
}) => {

    let url, icon;
    switch (entityType) {
        case `LOBBYIST`: 
            url = `/lobbyist/?name=${encodeURIComponent(name)}`; 
            icon = faUser;
            break;
        case `CONSULTING_FIRM`: 
            url = `/consulting-firm/?name=${encodeURIComponent(name)}`; 
            icon = faBriefcase;
            break;
        case `COMPANY`: 
            url = `/company/?name=${encodeURIComponent(name)}`; 
            icon = faIndustry;
            break;
        case `INSTITUTION`: 
            url = `/institution/?name=${encodeURIComponent(name)}`; 
            icon = faInstitution;
            break;
        case `PUBLIC_SERVANT`:
        default: 
            url = `/public-servant/?name=${encodeURIComponent(name)}`; 
            icon = faCanadianMapleLeaf;
            break;
    }

    let classes = [`text-gray-800`, `flex`, `gap-2`, `items-center`, `leading-snug`, `w-full`, `px-2`, `py-0.5`, `rounded-lg`]
    // this is to work around the way NavLink treats links as active or inactive
    if (name !== searchName) {
        classes.push(`inactive`);
    }

    return (
        <NavLink 
            to={url}
            end
            className={classes.join(' ')}
        >
            <FontAwesomeIcon 
                icon={icon} 
                className='fas min-w-[20px] -ml-1 mr-1 text-mid-grey' 
                size='xs'
            />
            <span className='truncate ellipsis text-sm'>
                {name}
            </span>
        </NavLink>
    )
}

export default FollowListItem;