

export const CANADA_RED = 'red';
export const DARK_GRAY_BLUE = '#575761';
export const HIGHLIGHT = 'rgb(255 244 187)';
export const PRIMARY = `#6b44fb`;

export const ACCENT_COMPANY = `#6956e3`;
export const ACCENT_CONSULTING = `#844f2d`;
export const ACCENT_INSTITUTION = `rgb(147, 146, 122)`;
export const BG_OFFWHITE = `#f4f3f1`;
export const MID_GREY = `#93927a`;
export const DARK_GREY = `#82827f`;
