import React from 'react';
import { Card, Chip } from '@nextui-org/react';
import { Link, useNavigate } from 'react-router-dom';
import { HIGHLIGHT } from '../../../../../../utils/colours';
import SearchResultCard from '../SearchResultCard/SearchResultCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndustry } from '@fortawesome/free-solid-svg-icons';

const CompanySearchResult = ({
    name,
    meetingCount,
    officialCount,
    highlight,
    handleClose,
    style,
    condensed
}) => {

    let highlightBlock;
    if (highlight) {
        const highlightSegments = highlight.map((h, i) => {
            switch (h.type) {
                case 'hit': return (
                    <span key={i}
                        className='px-1 py-0.5 rounded-md -mx-1 bg-highlight'
                    >
                        {h.value}
                    </span>
                );
                case 'text': 
                default: return <span key={i}>{h.value}</span>; 
            }
        })
        
        highlightBlock = (
            <p className='text-sm my-0.5 font-normal'>
                {highlightSegments}
            </p>
        )
    }

    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault();
        navigate(`/company/?name=${encodeURIComponent(name)}`)
        handleClose();
    }

    return (
        <Link 
            to={`/company/?name=${encodeURIComponent(name)}`} 
            onClick={handleClick}
            style={style}
        >
            <SearchResultCard 
                style={style} 
                condensed={condensed}
            >
                <div className='flex gap-4'>
                    <div>
                        <FontAwesomeIcon
                            icon={faIndustry}
                            className='text-mid-grey'
                            size={condensed ? 'sm' : null}
                        />
                    </div>
                    <div>
                        <p>{name}</p>
                        {!condensed && (
                            <div>
                                {highlightBlock}
                                {meetingCount > 0 && (
                                    <p className='text-gray-500 text-sm'>
                                        {meetingCount} meeting{meetingCount !== 1 && 's'} this year
                                    </p>
                                )}
                                {meetingCount === 0 && (
                                    <p className='text-gray-500 text-sm'>
                                        No meetings with government this year
                                    </p>
                                )}
                            </div>
                        )}
                    </div>
                    {/* <div>
                        <Chip size='sm' className='mb-2'>
                            <span className='font-medium'>Company</span>
                        </Chip>
                    </div> */}
                </div>
            </SearchResultCard>
        </Link>
    )

}

export default CompanySearchResult;