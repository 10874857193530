import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SearchResultCard from '../SearchResultCard/SearchResultCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const LobbyistSearchResult = ({
    name,
    consultingFirm,
    meetingCount,
    handleClose,
    style,
    condensed
}) => {

    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault();
        navigate(`/lobbyist/?name=${encodeURIComponent(name)}`)
        handleClose();
    }

    return (
        <Link  
            to={`/lobbyist/?name=${encodeURIComponent(name)}`} 
            onClick={handleClick}
            style={style}
        >
            <SearchResultCard 
                style={style} 
                condensed={condensed}
            >
                <div className='flex gap-4'>
                    <div>
                        <FontAwesomeIcon
                            icon={faUser}
                            className={condensed ? 'text-mid-grey ml-px' : 'text-mid-grey'}
                            size={condensed ? 'sm' : null}
                        />
                    </div>
                    <div>
                        <p>{name}</p>
                        {!condensed && (
                            <div>
                                <p className='text-gray-500 text-sm'>
                                    {consultingFirm[0]} • {meetingCount} meeting{meetingCount !== 1 && 's'} this year
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </SearchResultCard>
        </Link>
    )

}

export default LobbyistSearchResult;