import { gql } from "@apollo/client";

const CONSULTING_FIRM_CLIENTS = gql`
    query ConsultingFirmClients($name: String) {
        firms(consultingFirm: $name) {
            _id
            name
            meetingCount
            contactCount
            subjects {
                subject
                start
            }
            institutions {
                name
            }
        }
    }
`

export default CONSULTING_FIRM_CLIENTS;