import { gql } from "@apollo/client";

const VIEW_PAGE = gql`
    mutation ViewPage($name: String, $entityType: String) {
        viewPage(name: $name, entityType: $entityType) {
            name
            entityType
            views
        }
    }
`

export default VIEW_PAGE;