import { gql } from "@apollo/client";

const PROFILE_EXAMPLES = gql`
    query ProfileExamples(
        $company1: String, 
        $company2: String, 
        $consulting1: String, 
        $institution1: String,
        $institution2: String,
        $publicServant: String
    ) {
        companyProfile1: firm(name: $company1) {
            name
            subjects {
                subject
                start
            }
            lobbyists {
                name
                consultingFirm
            }
        }
        companyProfile1Relationships: relationships(firm: $company1, offset: 0, limit: 5) {
            _id
            officialName
            officialTitles
            officialInstitutions
            meetings
        }
        companyProfile2: firm(name: $company2) {
            name
            subjects {
                subject
                start
            }
            lobbyists {
                name
                consultingFirm
            }
        }
        companyProfile2Relationships: relationships(firm: $company2, offset: 0, limit: 5) {
            _id
            officialName
            officialTitles
            officialInstitutions
            meetings
        }
        consultingProfile1Lobbyists: lobbyists(consultingFirm: $company2, offset: 0, limit: 100) {
            name
        }
        consultingProfile1Firms: firms(consultingFirm: $consulting1) {
            name
            meetingCount
            contactCount
            subjects {
                subject
                start
            }
            institutions {
                name
            }
        }
        institution1: institution(institution: $institution1) {
            officialCount
            meetingCount
        }
        institution2: institution(institution: $institution2) {
            officialCount
            meetingCount
        }
        official: official(name: $publicServant) {
            givenName
            familyName
            title
            branch
            institution
        }
        officialMeetingCount: totalTimeline(publicServant: $publicServant)
    }
`

export default PROFILE_EXAMPLES;