import { gql } from "@apollo/client";


const GOOGLE_LOGIN = gql`
    mutation GoogleLogin($token: String!) {
        googleLogin(token: $token) {
            user {
                _id
                name
                email
            }
            token
            error
        }
    }
`

export default GOOGLE_LOGIN;