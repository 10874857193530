import React from 'react';
import { Card } from '@nextui-org/react';
import { Link, useNavigate } from 'react-router-dom';
import InstitutionName from '../../../../../ui/InstitutionName/InstitutionName';
import SearchResultCard from '../SearchResultCard/SearchResultCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstitution } from '@fortawesome/free-solid-svg-icons';
import { faCanadianMapleLeaf } from '@fortawesome/free-brands-svg-icons';

const PublicServantSearchResult = ({
    name,
    title,
    branch,
    institution,
    meetingCount,
    handleClose,
    style,
    condensed
}) => {

    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault();
        navigate(`/public-servant/?name=${encodeURIComponent(name)}`)
        handleClose();
    }

    return (
        <Link to={`/public-servant/?name=${encodeURIComponent(name)}`} 
            onClick={handleClick}
            style={style}
            
        >
            <SearchResultCard 
                style={style} 
                condensed={condensed}
            >
                <div className='flex gap-4'>
                    <div>
                        <FontAwesomeIcon
                            icon={faCanadianMapleLeaf}
                            className='text-mid-grey'
                            size={condensed ? 'sm' : null}
                        />
                    </div>
                    <div>
                        <p>{name}</p>
                        {!condensed && (
                            <div>
                                <p className='text-gray-500 text-sm'>
                                    {title[0]}, <InstitutionName fullName={institution[0]} />
                                </p>
                                <p className='text-gray-500 text-sm'>
                                    {meetingCount} meeting{meetingCount !== 1 && 's'} this year
                                </p>
                            </div>   
                        )}
                    </div>
                 
                </div>
            </SearchResultCard>
        </Link>
    )

}

export default PublicServantSearchResult;