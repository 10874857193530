import { createSlice } from '@reduxjs/toolkit'
import { getProfileViewCount } from '../../utils/authUtils';

const initialState = {
  user: null,
  signInEmail: null,
  profileViewCount: Number(getProfileViewCount())
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
        setUser: (state, action) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.user = action.payload
        },
        setUserPaymentActive: (state, action) => {
          if (!state.user) {
            return state;
          }
          state.user = {
            ...state.user,
            paymentStatus: 'SUCCEEDED'
          }
        },
        setSignInEmail: (state, action) => {
            state.signInEmail = action.payload;
        },
        addProfileView: (state, action) => {
          state.profileViewCount = state.profileViewCount + 1;
        }
    },
})

// Action creators are generated for each case reducer function
export const { 
  setUser, 
  setSignInEmail, 
  setUserPaymentActive,
  addProfileView
} = authSlice.actions

export default authSlice.reducer