import { useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import analytics from '../analytics/analytics'
import { useMutation } from '@apollo/client';
import VIEW_PAGE from '../components/InstitutionProfile/mutations/viewPage';

export const getEntityTypeFromPathname = (path) => {
  let type;
  switch (path) {
    case '/institution/': type = 'INSTITUTION'; break;
    case '/company/': type = 'COMPANY'; break;
    case '/consulting-firm/': type = 'CONSULTING_FIRM'; break;
    case '/lobbyist/': type = 'LOBBYIST'; break;
    case '/public-servant/': type = 'PUBLIC_SERVANT'; break;
    default: return;
  }
  return type;
}

export const useAnalytics = () => {
  const location = useLocation(); 

  const [searchParams, setSearchParams] = useSearchParams();

  const [logPageView, { loading, error, data }] = useMutation(VIEW_PAGE);

  useEffect(() => {
    // Track page views on route change
    const name = searchParams.get('name');
    console.log('profile --- ', name, location);
    analytics.page();

    if (name) {
      logPageView({
        variables: {
          name: name,
          entityType: getEntityTypeFromPathname(location.pathname)
        }
      })
    }

  }, [location])

  const trackEvent = (eventName, properties = {}) => {
    analytics.track(eventName, properties)
  }

  return { trackEvent }
}
