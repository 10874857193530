import { useLazyQuery } from '@apollo/client';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, Modal, ModalContent, Spinner, Tooltip, useDisclosure } from '@nextui-org/react';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import SEARCH from './queries/search';
import SearchResults from './SearchResults/SearchResults';
import SearchModal from './SearchModal/SearchModal';

const Search = ({
    collapsed,
    menuShape
}) => {

    const {isOpen, onOpen, onOpenChange, onClose} = useDisclosure();

    let btnClasses = `justify-between py-0 mb-1 pl-3 pr-1 leading-none rounded-2xl text-gray-500 hover:text-gray-800 transition-all h-9 flex bg-white`
    if (collapsed) {
        btnClasses = 'px-0 min-w-0 h-8 bg-transparent'
    }

    return (
        <Fragment>
            <Tooltip 
                content={collapsed ? 'Search' : ''}
                color='foreground' 
                placement='right'
                className={collapsed ? '' : 'hidden'}
                isTriggerDisabled={!collapsed}
            >
                <Button
                    className={btnClasses}
                    variant='flat'
                    onPress={onOpen}
                    disableAnimation
                    disableRipple
                >
                    <span className='flex gap-2.5'>
                        <FontAwesomeIcon icon={faSearch} color={`rgb(147, 146, 122)`} />
                        {!collapsed && (
                            <span>
                                Search...
                            </span>
                        )}
                    </span>
                </Button>
            </Tooltip>
            <SearchModal
                isOpen={isOpen}
                onClose={onClose}
                menuShape={menuShape}
            />
        </Fragment>
    )
}

export default Search;
