import { configureStore } from '@reduxjs/toolkit'

import authReducer from './slices/authSlice';
import uiReducer from './slices/uiSlice';
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = configureStore({
    reducer: {
        auth: authReducer,
        ui: uiReducer
    },
})
  