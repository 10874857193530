const mergePagination = (existing = [], incoming, { args }) => {
    const offset = args?.offset || 0;
    const merged = existing ? existing.slice(0) : [];
    for (let i = 0; i < incoming.length; ++i) {
      merged[offset + i] = incoming[i];
    }
    return merged;
}

const mergeSearchPagination = (existing = [], incoming, { args }) => {
    const offset = args?.offset || 0;
    const mergedResults = existing && existing.results ? existing.results.slice(0) : [];
    for (let i = 0; i < incoming.results.length; ++i) {
      mergedResults[offset + i] = incoming.results[i];
    }
    return {
        ...existing,
        results: mergedResults
    };
}

export const cacheOptions = {
    typePolicies: {
        Query: {
            fields: {
                timeline: {
                    // Don't cache separate results based on
                    // any of this field's arguments.
                    keyArgs: ["firm", "official", "consultingFirm", "lobbyist", "institution", "publicServant"],
          
                    // Concatenate the incoming list items with
                    // the existing list items.
                    merge(existing = [], incoming) {
                        return [...existing, ...incoming];
                    },
                },
                totalOfficials: {
                    // Don't cache separate results based on
                    // any of this field's arguments.
                    keyArgs: ["institution"],
                },
                search: {
                    keyArgs: ["query"],
                    merge(existing = { results: [] }, incoming) {
                        const mergedResults = existing.results.concat(incoming.results);
                        return {
                          ...incoming,
                          results: mergedResults,
                        };
                    },
                },
                firms: {
                    keyArgs: ["lobbyist", "consultingFirm"],
                    merge: mergePagination
                },
                relationships: {
                    keyArgs: ["firm", "official", "lobbyist", "institution"],
                    merge: mergePagination
                },
                consultingFirms: {
                    keyArgs: [],
                    merge: mergePagination
                },
                lobbyists: {
                    keyArgs: ["consultingFirm"],
                    merge: mergePagination
                },
                institutions: {
                    keyArgs: [],
                    merge: mergePagination
                },
                officials: {
                    keyArgs: ["institution"],
                    merge: mergePagination
                },
            }
        }
    }
}