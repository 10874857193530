import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { MID_GREY } from '../../../utils/colours';
import { faBug } from '@fortawesome/free-solid-svg-icons';

const PageError = ({

}) => {

    return (
        <div className='mt-16'>
            <FontAwesomeIcon
                icon={faBug}
                size='2xl'
                color={MID_GREY}
            /> 
            <p className='mt-4 text-3xl md:text-5xl tracking-tight flex gap-3 items-center'>
            <span>
                Something went wrong
            </span>
            </p>
            <p className='text-2xl mt-2 mb-6'>
                Sorry about that. We've received an alert and will investigate.
            </p>
            <p>If you run into ongoing issues, reach out at <a href="mailto:support@lobby.report" className='font-normal text-accent-fade hover:text-accent'>support@lobby.report</a>.</p>
        </div>
    )
}

export default PageError;