import React from 'react';
import { faCanadianMapleLeaf } from '@fortawesome/free-brands-svg-icons';
import { faBriefcase, faIndustry, faInstitution, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TypeIcon = ({
    type
}) => {

    let icon;
    switch (type) {
        case 'consulting-firm': icon = faBriefcase; break;
        case 'lobbyist': icon = faUser; break;
        case 'public-servant': icon = faCanadianMapleLeaf; break;
        case 'institution': icon = faInstitution; break;
        case 'company': 
        default: icon = faIndustry;
    }

    return (
        <FontAwesomeIcon 
            icon={icon} 
            className='fas text-mid-grey' 
            size='sm'
        />
    )
}

export default TypeIcon;