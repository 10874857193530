export const localStorageKey = `__lobby-token__`;
export const profileViewCountToken = `__lr_pl__`;
export const viewedProfilesToken = `__lr_vp__`
export const currentMonthToken = `__lr_m__`;
export const MAX_FREE_PROFILE_VIEWS = 101;

export const setLocalToken = (token) => {
    window.localStorage.setItem(localStorageKey, token);
}

export const getToken = () => {
    return window.localStorage.getItem(localStorageKey);
}

export const checkProAccess = (user) => {
    return user.plan === 'PRO' && user.paymentStatus === 'SUCCEEDED';
}

export const getCurrentMonthToken = () => {
    return window.localStorage.getItem(currentMonthToken);
}

export const setCurrentMonthToken = (month) => {
    window.localStorage.setItem(currentMonthToken, month);
}

export const resetProfileLimit = () => {
    window.localStorage.setItem(profileViewCountToken, 0);
    window.localStorage.setItem(viewedProfilesToken, JSON.stringify([]));
}

// if the current month has changed, reset the counter
export const resetLimitMonthly = () => {
    const date = new Date();
    const currentMonth = date.toLocaleString('default', { month: 'long' });  
    const currentMonthToken = getCurrentMonthToken();
    if (!currentMonthToken || currentMonthToken !== currentMonth) {
        setCurrentMonthToken(currentMonth);
        resetProfileLimit();
    }
}

export const getProfileViewCount = () => {
    return window.localStorage.getItem(profileViewCountToken);
}

export const getViewedProfiles = () => {
    return JSON.parse(window.localStorage.getItem(viewedProfilesToken));
}

export const checkHasViewedProfile = (profileName) => {
    const viewedProfiles = getViewedProfiles();
    return viewedProfiles && viewedProfiles.includes(profileName);
}

// increment the profile limit counter for each profile view
export const countProfileView = (profileName) => {

    resetLimitMonthly();

    const profileViewCount = Number(getProfileViewCount()); 
    window.localStorage.setItem(profileViewCountToken, profileViewCount+1);

    const viewedProfiles = getViewedProfiles();
    const newViewedProfiles = [...viewedProfiles, profileName];
    window.localStorage.setItem(viewedProfilesToken, JSON.stringify(newViewedProfiles));

}

