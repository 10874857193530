import { gql } from "@apollo/client";

const LOBBYIST_CLIENTS = gql`
    query LobbyistClients($lobbyist: String) {
        totalClients(lobbyist: $lobbyist)
        firms(lobbyist: $lobbyist) {
            _id
            name
            subjects {
                subject
                start
            }
            meetingCount
            contactCount
            institutions {
                name
            }
        }
    }
`

export default LOBBYIST_CLIENTS;