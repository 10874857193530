import { useLazyQuery } from '@apollo/client';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal, ModalContent, Spinner } from '@nextui-org/react';
import React, { useCallback, useEffect, useState } from 'react';
import SEARCH from '../queries/search';
import _ from 'lodash';
import SearchResults from '../SearchResults/SearchResults';

const SearchModal = ({
    isOpen,
    onClose,
    menuShape
}) => {

    const [query, setQuery] = useState('');
    const [prevQuery, setPrevQuery] = useState('');
    const [hasMoreResults, setHasMoreResults] = useState(true); 

    const handleClose = () => {
        setQuery('');
        setPrevQuery('');
        onClose()
    }

    const [ onSearch, { loading, error, data, previousData, fetchMore } ] = useLazyQuery(SEARCH, {
        variables: {
            query: query,
            offset: 0,
            limit: 10
        }
    });

    const handleSearch = (query) => {
        setPrevQuery(query);
        onSearch({
            variables: {
                query: query,
                offset: 0,
                limit: 10
            }
        })
    }

    const [loadingMore, setLoadingMore] = useState(false);

    const onFetchMore = () => {
        setLoadingMore(true);
        fetchMore({
            variables: {
                offset: data.search.results.length
            }
        })
    }

    useEffect(() => {
        if (data && previousData) {
            setHasMoreResults(data.search.length !== previousData.search.length);
        }
        if (loadingMore) {
            setLoadingMore(false);
        }
    }, [data])

    const debouncedSearch = useCallback(_.debounce(handleSearch, 300), []); 

    useEffect(() => {
        if (!isOpen) {
            setQuery('');
        }
    }, [isOpen]);

    const onChangeQuery = (e) => {
        setQuery(e.target.value);
        if (e.target.value.length >= 3) {
            // onSearch();
            debouncedSearch(query)
        }
    }

    useEffect(() => {
        if (query.trim() !== prevQuery.trim()) {
            debouncedSearch(query);
        }
    }, [data]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={handleClose}
            className='max-w-2xl'
            closeButton={<Button style={{ display: 'none' }}>esc</Button>}
            placement={menuShape === 'MOBILE' ? 'top' : 'auto'}
            motionProps={{
                transition: {
                    duration: 0
                }
            }}
        >
            <ModalContent>
                <div className='py-4 px-6 text-xl font-medium border-b border-gray-200 flex gap-3 items-center'>
                    <FontAwesomeIcon 
                        style={{ position: 'relative', top: 1 }}
                        icon={faSearch} 
                        color='#999'
                        size='sm'
                    />
                    <input 
                        autoFocus
                        placeholder='Search...'
                        className='flex-grow bg-white text-black font-normal'
                        value={query}
                        onChange={onChangeQuery}
                    />
                    {loading && (
                        <div className='flex'>
                            <Spinner size='sm' color='default' />
                        </div>
                    )}
                </div>
                
                {/* <Input 
                    size='lg'
                    className='rounded-none'
                    placeholder='Search...'
                /> */}
                {prevQuery && (
                    <div className='max-h-96 overflow-scroll'>
                        <SearchResults
                            data={data ? data : previousData ? previousData : null}
                            handleClose={handleClose}
                            onFetchMore={onFetchMore}
                            loadingMore={loadingMore}
                            hasMoreResults={hasMoreResults}

                        />
                    </div>
                )}
            </ModalContent>
        </Modal>
    )
}

export default SearchModal;