import { faCanadianMapleLeaf } from '@fortawesome/free-brands-svg-icons';
import { faBriefcase, faCalendar, faIndustry, faInstitution, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalContent } from '@nextui-org/react';
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { closeAuthModal, openSignInModal, openSignUpModal } from '../../../../store/slices/uiSlice';
import { setUser } from '../../../../store/slices/authSlice';
import { setLocalToken } from '../../../../utils/authUtils';

const MobileUserMenu = ({
    isOpen,
    onClose
}) => {

    const navLinkClasses = 'flex gap-2 items-center w-full px-0 pt-2 pb-2 rounded-lg';
    
    const authModal = useSelector(state => state.ui.authModal);
    const user = useSelector(state => state.auth.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onOpenSignUp = () => {
        dispatch(openSignUpModal());
        onClose();
    }
    const onCloseAuthModal = () => {
        dispatch(closeAuthModal());
    }

    const [signInIsOpen, toggleSignInOpen] = useState(false);
    const onOpenSignIn = () => {
        dispatch(openSignInModal());
        onClose();
    }

    const onSignOut = () => {
        setLocalToken('');
        dispatch(setUser(null));
        navigate('/');
        onClose();
    }

    const anonMenu = (
        <Fragment>
            <button className='text-lg font-medium bg-transparent text-black py-1'
                onClick={onOpenSignIn}
            >
                Log in
            </button>
            <button className='text-lg font-medium bg-transparent text-black py-1'
                onClick={onOpenSignUp}
            >
                Sign up
            </button>
        </Fragment>
    )

    const signedInMenu = (
        <Fragment>
            {user && user.plan === 'PRO' && (
                <a 
                    href={import.meta.env.VITE_STRIPE_PORTAL_LINK}
                    target="_blank"
                    className='text-center'
                >
                    <button className='text-lg font-medium bg-transparent text-black py-1'
                    >
                        Manage billing
                    </button>
                </a>
            )}
            <button className='text-lg font-medium bg-transparent text-black py-1'
                onClick={onSignOut}
            >
                Sign out
            </button>
        </Fragment>
    )

    const menu = user ? signedInMenu : anonMenu;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            classNames={{
                base: 'z-50',
                closeButton: 'hidden'
            }}
        >
            <ModalContent>
                <div className='flex flex-col py-4'>
                    {menu}
                </div>
            </ModalContent>
        </Modal>
    )
}

export default MobileUserMenu;