import React from 'react';
import { Card } from '@nextui-org/react';
import { Link } from 'react-router-dom';

const SubjectSearchResult = ({
    name,
    highlight,
    handleClose,
    style,
    condensed
}) => {

    const highlightSegments = highlight.map((h, i) => {
        switch (h.type) {
            case 'hit': return <span key={i} className='font-bold'>{h.value}</span>;
            case 'text': 
            default: return <span key={i}>{h.value}</span>; 
        }
    })

    return (
        <Link 
            to={`/company/?name=${encodeURIComponent(name)}`} 
            onClick={handleClose}
            style={style}
        >
            <Card
                className="mx-4 my-2 px-4 py-3 text-lg rounded-md leading-tight font-medium hover:bg-gray-100 cursor-pointer"
            >
                <p className=''>{name}</p>
                <p className='text-gray-500 text-sm'>
                    {highlightSegments}
                </p>
            </Card>
        </Link>
    )

}

export default SubjectSearchResult;