import React from "react";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const LoadingSidePeek = ({

}) => {
    return (
        <div style={{ marginTop: `calc(44vh - 80px)` }}>
            <LoadingSpinner />
        </div>
    )
}

export default LoadingSidePeek;