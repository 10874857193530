import { gql } from "@apollo/client";

const COMPANY_SCATTERPLOT = gql`
    query CompanyScatterplot {
        companiesScatterplot: scatterplot(content: "COMPANIES") {
            label
            type    
            xVal
            yVal
        }
        institutionScatterplot: scatterplot(content: "INSTITUTIONS", limit: 30) {
            label
            type    
            xVal
            yVal
        } 
        consultingScatterplot: scatterplot(content: "CONSULTING_FIRMS", limit: 30) {
            label
            type    
            xVal
            yVal
        }
        publicServantsScatterplot: scatterplot(content: "PUBLIC_SERVANTS", limit: 50) {
            label
            sublabel
            type    
            xVal
            yVal
        }
    }
`

export default COMPANY_SCATTERPLOT;