import React from 'react';
import './LoadingSpinner.css';

const LoadingSpinner = ({

}) => {

    return (
        <div className='loader'>Loading...</div>
    )
}

export default LoadingSpinner;