import React, { useEffect } from "react";
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from "react-router-dom";

const AuthenticatedSpace = ({

}) => {

    const navigate = useNavigate();
    const user = useSelector(state => state.auth.user);

    // useEffect(() => {
    //     if (!user) {
    //         console.log('user', user);
    //         navigate('/');
    //     }

    // }, [user]);

    // if (user) {
        return (
            <div>
                <Outlet />
            </div>
        )
    // }

    return null;

   
}

export default AuthenticatedSpace;