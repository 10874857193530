import React from "react";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const ProfileLoading = ({

}) => {

    return (
        <div className="flex items-center">
            <div className="flex justify-center flex-grow items-center p-4"
                style={{ marginTop: 'calc(35vh - 4rem - 40px' }}
            >
                <div className="flex-grow items-center">
                    <LoadingSpinner />
                </div>
            </div>
        </div>
    )

}

export default ProfileLoading;