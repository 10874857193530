import { Card } from "@nextui-org/react";
import React from "react";
import { Link } from "react-router-dom";
import InstitutionChip from "../../../ui/InstitutionChip/InstitutionChip";

// TODO: Generalize this

const PublicServantBlock = ({
    name,
    title,
    institution,
    officialName,
    officialTitles,
    officialInstitutions,
    meetings,
    contextName
}) => {

    return (
        <Link to={`/public-servant/?name=${encodeURIComponent(officialName)}`}
            className="block leading-snug rounded-lg"
        >
            <Card shadow='sm' className="px-5 py-4">
                <p 
                    className="truncate font-semibold flex justify-between" 
                    // onClick={openOfficialSidePeek}
                >
                    <span className="truncate md:max-w-40">
                        {name ? name :officialName}
                    </span>
                    {officialInstitutions && (
                        <span className="-mt-px">
                            <InstitutionChip institution={officialInstitutions[0]} />
                        </span>
                    )}
                </p>
                <p className="text-dark-grey text-sm flex justify-between items-start">
                    <span className="truncate ellipsis inline-block">
                        {title ? title : officialTitles[0]}, {institution ? institution : officialInstitutions[0]}
                    </span>
                </p>
                <p className="text-dark-grey text-xs mt-2">
                    {meetings} meeting{meetings > 1 ? 's' : ''} {contextName ? `with ${contextName}` : 'with lobbyists'} this year
                </p>
            </Card>
        </Link>
    )

}

export default PublicServantBlock;