import { Avatar, Button, cn, Divider, Input, Modal, ModalContent, Popover, PopoverContent, PopoverTrigger, Radio, RadioGroup } from "@nextui-org/react";
import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { setLocalToken } from "../../../utils/authUtils";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../../store/slices/authSlice";
import SignUpModal from "../SignUpModal/SignUpModal";
import SignInModal from "../SignInModal/SignInModal";
import { closeAuthModal, openSignInModal, openSignUpModal } from "../../../store/slices/uiSlice";
import UpgradeModal from "../UpgradeModal/UpgradeModal";

export const CustomRadio = (props) => {
    const {children, ...otherProps} = props;
  
    return (
      <Radio
        {...otherProps}
        classNames={{
          base: cn(
            "inline-flex m-0 bg-content1 hover:bg-content2 items-center",
            "flex-row max-w-[300px] cursor-pointer rounded-lg gap-4 p-4 border-2 border-transparent",
            "data-[selected=true]:border-primary"
          ),
        }}
      >
        {children}
      </Radio>
    );
};

const UserMenu = ({
    user
}) => {

    const authModal = useSelector(state => state.ui.authModal);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onOpenSignUp = () => {
        dispatch(openSignUpModal());
    }
    const onCloseAuthModal = () => {
        dispatch(closeAuthModal());
    }

    const [signInIsOpen, toggleSignInOpen] = useState(false);
    const onOpenSignIn = () => {
        dispatch(openSignInModal());
    }

    const onSignOut = () => {
        setLocalToken('');
        dispatch(setUser(null));
    }

    let menu = (
        <div className="flex items-end gap-1 z-40">
            <Button size="sm" 
                className="py-0 font-semibold h-6 min-w-12 px-2"
                disableAnimation
                disableRipple
                onClick={onOpenSignIn}
            >
                Log in
            </Button>
            <Button 
                size="sm" 
                className="py-0 font-semibold h-6 min-w-12 px-2"
                onClick={onOpenSignUp}
                disableAnimation
                disableRipple
            >
                Sign up
            </Button>
        </div>
    )

    if (user) {

        const initial = user.email[0].toUpperCase();

        menu = (
            <Popover>
                <PopoverTrigger>
                    <Avatar
                        as="button"
                        className="transition-transform xs-avatar bg-white shadow-sm border border-gray-200"
                        color='default'
                        showFallback
                        fallback={initial}
                        size="sm"
                    />
                </PopoverTrigger>
                <PopoverContent className="w-64">
                    <div className="px-1 py-2 text-black w-full">
                        <div className="text-xs mb-2 border-b pb-2 opacity-80">
                            {user.email}
                        </div>
                        <div className="flex flex-col gap-1">
                            {user.plan === 'PRO' && (
                                <a 
                                    href={import.meta.env.VITE_STRIPE_PORTAL_LINK}
                                    target="_blank"
                                >
                                    <Button 
                                        fullWidth
                                        size="sm"
                                        className="py-0 font-medium bg-transparent justify-start opacity-60 px-0" 
                                    >
                                        Manage billing
                                    </Button>
                                </a>    
                            )}
                            <Button 
                                fullWidth
                                size='sm'
                                className="py-0 px-0 font-medium bg-transparent justify-start opacity-60 hover:opacity-80"  
                                disableRipple
                                onClick={onSignOut}
                            >
                                Sign out
                            </Button>
                        </div>
                    </div>
                </PopoverContent>
            </Popover>
        )
    }


    return (
        <>
            {menu}
             <SignUpModal
                isOpen={authModal === 'SIGN_UP'}
                onClose={onCloseAuthModal}
            />
            <SignInModal
                isOpen={authModal === 'SIGN_IN'}
                onClose={onCloseAuthModal}
            />
            <UpgradeModal
                isOpen={authModal == 'UPGRADE'}
                onClose={onCloseAuthModal}
                isNewUser={!user ? true : false}
            />
        </>
    )
}

export default UserMenu;