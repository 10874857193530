import Analytics from 'analytics'
import googleAnalyticsPlugin from '@analytics/google-analytics'

console.log('tesT')

// Debug the plugin initialization
const gaPlugin = googleAnalyticsPlugin({
    measurementIds: ['G-KSFGC36D6F']
})

const analytics = Analytics({
  app: 'lobby-report',
  plugins: [gaPlugin]
})

export default analytics